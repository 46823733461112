$businessphoneprices-go-go-green: #008a7b;
$businessphoneprices-green: #5bc1a4;
$movemeout-default-checkbox-selected-bgcolor: #e8f3f8;
$movemeout-default-checkbox-border-color: #16556d;
$movemeout-radio-default-button-bgcolor: #00ade6;
$movemeout-radio-default-hover-button-bgcolor: #ebfaff;
$movemeout-radio-default-button-text-color: #000;
$movemeout-default-button-bgcolor: #00ade6;
$movemeout-default-button-hover-bgcolor: #0499cb;
$movemeout-light-bgcolor: #DFF5F9;
$movemeout-default-button-text-color: #000;
$movemeout-form-border-color: #2e2e2e;

$movemeout-progress-main-color: #aaa;
$movemeout-why-us-title-color: #2e2e2e;
$movemeout-why-us-item-title-color: #00ade6;
$movemeout-why-us-item-brief-color: #747474;
$movemeout-header-phone-color: #dd6453;
$movemeout-footer-color: #000;
:export {
    progressColor: $movemeout-progress-main-color;
    primaryColor: $movemeout-radio-default-button-bgcolor;
    lightPrimaryColor: $movemeout-light-bgcolor;
    green: $businessphoneprices-green;
    whyUsTitle: $movemeout-why-us-title-color;
    whyUsItemTitle: $movemeout-why-us-item-title-color;
    whyUsItemBrief: $movemeout-why-us-item-brief-color;
    headerPhoneColor: $movemeout-header-phone-color;
    offerCallNumber:$movemeout-default-button-bgcolor;
}
