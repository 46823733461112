
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.main-banner {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: flex-start;
    height: auto;
    min-height: calc(100vh - 58px);

    .form {
        z-index: 5;
        width: 100%;
        padding: 30px 24px;
        max-width: 700px;
        border-radius: 10px;
        min-height: 500px;
        background-color: white;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
        position: relative;
        border: 2px solid $movemeout-form-border-color;
        box-shadow: 0px 11px 31px 8px rgba(218, 218, 218, 0.71);

        .steps-inner {
            flex-grow: 1;
        }
        h1 {
            &.step-title {
                @apply text-xl;
                font-weight: bold !important;
                margin: 0;
                @include lg {
                    @apply text-3xl;
                }
            }
        }
        .step-title {
            text-align: center;
            @apply text-xl;
            font-weight: 700 !important;
            margin-bottom: 10px;
            @include lg {
                @apply text-3xl;
            }
        }
        .label-as-step-title {
            text-align: center;
            font-size: 22px !important;
            font-weight: 700 !important;
            margin-bottom: 10px;
            margin-top: -20px;
            margin-bottom: 25px !important;
            @include lg {
                margin-left: -50px;
                margin-right: -50px;
            }
        }
        .step-description {
            text-align: center;
            font-weight: 300;
            @apply text-sm mb-2;
            @include lg {
                @apply text-base;
            }
        }
        .fields-wrapper {
            margin-top: 1.5rem;
        }
        .form-button {
            max-width: unset !important;
            margin: 0 auto !important;
            margin-top: 5px !important;
            border-radius: 30px !important;
            background-color: $movemeout-default-button-bgcolor !important;
            transition: all 0.2s ease-in-out;
            color: $movemeout-default-button-text-color;
            svg {
                path {
                    fill: $movemeout-default-button-text-color;
                }
            }
            @include lg {
                max-width: 450px !important;
            }
            &:hover {
                background-color: $movemeout-default-button-hover-bgcolor !important;
            }
        }
        .form-field {
            // margin-top: 2rem;
            max-width: unset;
            margin: 0 auto;
            margin-bottom: 0.5rem;

            @include lg {
                max-width: 450px;
            }
        }
        .step-block:nth-child(1) {
            min-height: 470px;
            .form-field > label {
                @apply text-base mb-4 font-bold;
                text-align: center;
                @include lg {
                    @apply text-lg;
                }
            }
        }
        .radio-field {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;

            margin-top: 10px;

            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        font-weight: 500;
                        background-color: $movemeout-radio-default-button-bgcolor;
                        border-color: $movemeout-radio-default-button-bgcolor;
                        color: $movemeout-default-button-text-color !important;
                    }
                }
                label {
                    border-radius: 30px;
                    border: 1px solid $movemeout-radio-default-button-bgcolor;
                    color: $movemeout-radio-default-button-text-color;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    &:hover {
                        background-color: $movemeout-radio-default-hover-button-bgcolor;
                        // color: white;
                    }
                    span {
                        font-size: 14px !important;
                        @include md {
                            font-size: 16px !important;
                        }
                    }
                    @include md {
                        min-height: 50px;
                    }
                    svg {
                        margin-bottom: 10px;
                        width: 30px;
                        height: auto;
                    }
                }
                @include md {
                    margin: 0 10px 15px;
                    width: 60% !important;
                    margin-left: auto;
                    margin-right: auto;
                }
                &:last-child {
                    margin-bottom: 10px !important;
                }
            }
            @include lg {
                margin: 0 -10px;
            }
        }
        .checkbox-field {
            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        font-weight: 500;
                        background-color: $movemeout-default-checkbox-selected-bgcolor;
                    }
                }
                label {
                    border-radius: 3px;
                    border: 1px solid $movemeout-default-checkbox-border-color;
                    color: #000;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    & > span:first-child {
                        border-radius: 3px;
                    }
                    @include md {
                        min-height: 50px;
                    }
                }
            }
        }
        .tcpa {
            margin-bottom: 10px;
        }
        @include lg {
            min-width: 400px;
            width: 60%;
            padding: 40px 40px;
            margin-bottom: 200px;
            margin-top: 50px;
        }
    }
    .image {
        z-index: 2;
        & > div {
            position: absolute !important;
            left: 0;
            bottom: 0;
            height: 300px;
            width: 100vw;
            max-width: unset !important;
            left: 50% !important;
            transform: translateX(-50%) !important;

            @include lg {
                height: 100%;
                top: 0 !important;
                left: 0%;
            }
        }

        img {
            min-height: unset !important;
            min-width: unset !important;
            width: auto !important;
            height: 100% !important;
            max-width: unset !important;
            margin-left: 0 !important;
            margin-right: auto !important;
        }
    }

    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        background-color: #f9f9f9;
        background-image: url("/assets/images/background_mobile.svg");
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: 100%;
        z-index: 2;
        @include md {
            background-image: url("/assets/images/background.svg");
            background-position: center center;
            background-size: cover;
        }
    }
}

.step-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 345px;
}
.selling-point {
    font-size: 11px;
    color: #999;
    padding-top: 10px;
}
